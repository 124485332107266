/* Toast start */
#toastMessage {
  color: white;
  background-color: #006200;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
  padding: 1em;
  z-index: 100002;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: fixed;
  width: fit-content;
  max-width: 50%;
  -webkit-animation: toastMessage 0.5s forwards;
  -webkit-animation-delay: 0.1s;
  animation: toastMessage 0.5s forwards;
  animation-delay: 0.1s;
}

@-webkit-keyframes toastMessage {
  100% {
    top: 0;
  }
}

@keyframes toastMessage {
  100% {
    top: 0;
  }
}

.highlight {
  background-color: #efa741;
}

.toastLink {
  position: relative;
  color: #fff;
  font-family: "ciscoSansRegular";
  margin-left: 20px;
  text-decoration: underline;
  padding: 0 5px;
}
.toastLink::before {
  position: absolute;
  right: 100%;
  top: 3px;
  background: url("./assets/images/refresh.svg") no-repeat center;
  width: 14px;
  height: 14px;
  background-size: 14px 14px;
  content: "";
}

#toastMessage.success {
  background-color: #008000;
}
#toastMessage.warning {
  background-color: #efa741;
}
#toastMessage.error {
  background-color: #f86969;
}

/* Toast End */

body {
  background-color: black;
}
