.footer {
  font-size: 1em;
  display: flex;
  justify-content: center;
  clear: both;
  color: "white";

  padding-top: 3vw;
  margin-top: 0em;
  .powered-by {
    font-family: "Sora";
    font-style: normal;
    font-weight: 200;
    color: #ffffff;
    float: left;
    padding-right: 1vw;
    line-height: 1.57vw;
  }
  .cisco-dnaspaces-logo {
    .cisco-logo {
      float: left;
    }
    .dnaspaces-logo {
      float: left;
      margin-left: 1vw;
      padding-top: 0.2vw;
    }
  }
  .webex-logo-wrapper {
    margin-left: 1.5vw;
    padding-left: 1vw;
    display: inline-block;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    .webex-logo {
      float: left;
      img {
        height: 2vw;
      }
    }
    .webex-text-logo {
      float: left;
      margin-left: 0.5vw;
      padding-top: 0.2vw;
    }
  }
}
