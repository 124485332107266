.configure {
  width: 100vw;
  height: 100vh;
  background-color: black;
  /* padding: 4em; */
  &.backimg {
    background: url("../../assets/images/sample-meetings.svg") no-repeat center;
    background-color: black;
  }
  .footer {
    font-size: 1em;
    clear: both;
    color: "white";
    .powered-by {
      font-family: "Sora";
      font-style: normal;
      font-weight: 200;
      color: #ffffff;
      float: left;
      padding-right: 1vw;
      line-height: 1.57vw;
    }
    .cisco-dnaspaces-logo {
      .cisco-logo {
        float: left;
      }
      .dnaspaces-logo {
        float: left;
        margin-left: 1vw;
        padding-top: 0.2vw;
      }
    }
    .webex-logo-wrapper {
      margin-left: 1.5vw;
      padding-left: 1vw;
      display: inline-block;
      border-left: 1px solid rgba(255, 255, 255, 0.4);
      .webex-logo {
        float: left;
        img {
          height: 2vw;
        }
      }
      .webex-text-logo {
        float: left;
        margin-left: 0.5vw;
        padding-top: 0.2vw;
      }
    }
  }
  .body {
    font-size: 2em;
    margin-top: 0em;
    color: "white";
    display: flex;
    justify-content: space-evenly;
    .workspaces-panel {
      width: 35vw;
      float: left;
      padding-right: 2vw;
      margin-top: 2em;
      .spacer {
        height: 8vw;
      }

      .header {
        font-size: 2.45vw;
        line-height: 2.75vw;
        color: #ffffff;
      }

      .code {
        font-size: 13vw;
        font-family: Arial;
        text-align: center;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        color: #e43978;
        font-weight: 700;
      }

      .workspaces-logo {
        font-weight: 850;
        align-items: center;
        color: #00bceb;
        line-height: 5.5vw;
        font-size: 3.27vw;
        margin-left: 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .logo-text {
        color: #ffffff;
        line-height: 2.2vw;
        font-size: 1.79vw;
        margin-left: 2em;
      }
    }
    .steps {
      width: 40vw;
      float: left;
      margin: 1em;
      margin-top: 6em;
      border-left: 2px solid rgba(255, 255, 255, 0.5);
      padding-left: 3vw;
      .follow {
        font-size: 1.67vw;
        line-height: 2.25vw;
        color: #ffffff;
      }

      ol {
        list-style: square outside none;
        font-size: 1.95vw;
        line-height: 2.75vw;
        color: #ffffff;
        counter-reset: li;
        list-style: none;
        position: relative;
        padding: 0;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
        li {
          padding-top: 1vw;
          padding-left: 4vw;
          .alink {
            font-size: 1.75vw;
            line-height: 2.5vw;
            color: #00bceb;
          }
          &::before {
            content: counter(li);
            counter-increment: li;
            position: absolute;
            left: 0vw;
            height: 2vw;
            margin-top: 0.3vw;
            width: 2vw;
            line-height: 2.1vw;
            border: 0.15vw solid #fff;
            text-align: center;
            font-weight: 200;
            font-size: 1.4vw;
            border-radius: 2vw;
            transition: all 0.3s ease-out;
          }
        }
      }
    }
  }
}
