.activation {
  width: 100vw;
  height: 100vh;
  background-color: black;
  &.backimg {
    background: url("../../assets/images/sample-meetings.svg") no-repeat center;
    background-color: black;
  }
  .footer {
    font-size: 1vw;
    clear: both;
    padding-top: 3vw;
    .powered-by {
      font-family: "Sora";
      font-style: normal;
      font-weight: 200;
      color: #ffffff;
      float: left;
      padding-right: 1vw;
      line-height: 1.57vw;
      font-size: 1.25vw;
    }
    .cisco-dnaspaces-logo {
      .cisco-logo {
        float: left;
      }
      .dnaspaces-logo {
        float: left;
        margin-left: 1vw;
        padding-top: 0.2vw;
      }
    }
  }
  .steps {
    width: 33vw;
    float: left;
    margin: 3em;
    margin-top: 6em;
    margin-right: 2vw;
    .follow {
      font-size: 1.67vw;
      line-height: 2.25vw;
      color: #ffffff;
    }

    ol {
      list-style: square outside none;
      font-size: 1.95vw;
      line-height: 2.75vw;
      color: #ffffff;
      counter-reset: li;
      list-style: none;
      padding: 0;
      position: relative;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
      li {
        padding-top: 1vw;
        padding-left: 4vw;
        .alink {
          font-size: 1.75vw;
          line-height: 2.5vw;
          color: #00bceb;
        }
        &::before {
          content: counter(li);
          counter-increment: li;
          position: absolute;
          left: 0vw;
          height: 2vw;
          width: 2vw;
          line-height: 2.1vw;
          border: 0.15vw solid #fff;
          text-align: center;
          font-weight: 200;
          font-size: 1.4vw;
          border-radius: 2vw;
          transition: all 0.3s ease-out;
        }
      }
    }
  }
  .code-display {
    width: 45vw;
    float: right;
    margin-top: 6em;
    padding-left: 5vw;
    .spacer {
      height: 7vw;
    }

    .header {
      font-size: 2.45vw;
      line-height: 2.75vw;
      color: #ffffff;
    }

    .code {
      font-size: 13vw;
      font-family: Arial;
      text-align: center;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      color: #e43978;
      font-weight: 700;
      min-height: 1.2em;
    }

    .workspaces-logo {
      font-weight: 850;
      align-items: center;
      color: #00bceb;
      line-height: 5.5vw;
      font-size: 3.27vw;
      display: flex;
      justify-content: center;
      margin-left: -1.8em;
    }
    .logo-text {
      color: #ffffff;
      line-height: 2vw;
      font-size: 1.51vw;
      margin-top: 1em;
    }
  }
}
